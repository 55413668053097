import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {createContext} from 'react';

export const GlobalContext = createContext()

export function GlobalContextProvider({children}) {
    const [darkMode, setDarkMode] = useState(false);
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        updateDarkMode(!darkMode)
    }

    useEffect(() => {
        const isDark = localStorage.theme === 'dark' ||
            ( !('theme' in localStorage) && 
                window.matchMedia('(prefers-color-scheme: dark)').matches );
        setDarkMode(isDark)
        updateDarkMode(isDark)
    }, [])

    const [filterIndex, setFilterIndex] = useState('0');
    return (
        <GlobalContext.Provider value={{ darkMode, toggleDarkMode, filterIndex, setFilterIndex }}>
            {children}
        </GlobalContext.Provider>
    );
}

function updateDarkMode(darkMode) {
    if (darkMode) {
        document.documentElement.classList.add('dark');
        localStorage.theme = 'dark'
    } else {
        document.documentElement.classList.remove('dark');
        localStorage.theme = 'light'
    }
}

export const useGlobalContext = () => useContext(GlobalContext)