import React from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import { BsFillSunFill, BsFillMoonStarsFill } from 'react-icons/bs';
import styles from './SubDarkmode.module.css'

export default function SubDarkmode() {
    const {darkMode, toggleDarkMode} = useGlobalContext()
    const displayMode = () => {
        return <BsFillSunFill />
    }

    return (
        <button className={styles.toggle} onClick={toggleDarkMode}>{!darkMode && <BsFillMoonStarsFill/>}{darkMode && <BsFillSunFill/>}</button>
    )
}

