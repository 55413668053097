import React from 'react';
import styles from './AppTodoList.module.css'
import TodoContents from './components/TodoContents';
import TodoMenu from './components/TodoMenu';
import { GlobalContextProvider } from './context/GlobalContext';

export default function AppTodoList() {

  return (
    <>
      <GlobalContextProvider>
        <TodoMenu />
        <TodoContents />
      </GlobalContextProvider>
    </>
  );
}

