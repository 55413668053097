import React from 'react';
import { useReducer } from 'react';
import { useState } from 'react';

import {v4 as uuidv4} from 'uuid';
import styles from './SubInput.module.css'

export default function SubInput({onAdd}) {
    const [text, setText] = useState('')


    const handleChangeItem = (e) => {
        setText(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (text.trim().length === 0 ) {
            setText('')
            return;
        }
        onAdd({id: uuidv4(), text, status: 'active'})
        setText('')

    }

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <input className={styles.input}
                type="text"
                id="text"
                name="text"
                placeholder='add todo'
                onChange={handleChangeItem}
                value={text}
            />
            <button className={styles.button}>Add</button>
        </form>
    );
}

