import React, { useContext } from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import SubDarkmode from './SubDarkmode';
import SubFilter from './SubFilter';
import styles from './TodoMenu.module.css'


export default function TodoMenu() {
    const {filterIndex, setFilterIndex} = useGlobalContext()
    
    const handleSelectSubFilter = (e) => {
        console.log('handleSelectSubFilter')
        console.log(filterIndex, typeof(filterIndex))
        console.log(e)
    }
    
    return (
        <header className={styles.header}>
            <SubDarkmode />
            <div className={styles.filters}>
                <SubFilter className={styles.filter} name='All' onClick={handleSelectSubFilter} title='All' selected={`${filterIndex === '0' ? 'true' : 'false'}`} filterIdx='0'/>
                <SubFilter className={styles.filter} name='Active' onClick={handleSelectSubFilter} selected={`${filterIndex === '1' ? 'true' : 'false'}`} filterIdx='1'/>
                <SubFilter  name='Completed' onClick={handleSelectSubFilter} selected={`${filterIndex === '2' ? 'true' : 'false'}`} filterIdx='2'/>

            </div>
        </header>
    );
}

