import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import itemReducer from '../reducer/items-reducer';
import SubInput from './SubInput';
import SubItem from './SubItem';
import styles from './TodoContents.module.css'

export default function TodoContents() {
    const {filterIndex} = useContext(GlobalContext)
    
    const [todos, setTodos] = useState(() => readTodosFromLocalStorage());

    const modifyTodo = (modifiedTodoList) => {
        setTodos(modifiedTodoList);
    }

    const handleAdd = (todo) => {
        console.log(todo);
        setTodos([...todos, todo]);
    }

    const handleUpdate = (updated) => {
        setTodos(todos.map((t) => (t.id==updated.id ? updated : t)))
    }

    const handleDelete = (deleted) => {
        setTodos(todos.filter((t) => (t.id!=deleted.id)))
    }

    useEffect(() => {
        localStorage.setItem('todos', JSON.stringify(todos))
        
    }, [todos])

    

    const newTodo = filteredTodo(todos, filterIndex)
    
    return <section className={styles.container}>
        <ul className={styles.list}>
            { newTodo.map( (item) => (
                    <SubItem 
                        key={item.id} 
                        todo={item} 
                        onUpdate={handleUpdate} 
                        onDelete={handleDelete} 
                    />
            ))}
        </ul>

        <SubInput onAdd={handleAdd} />
    </section>
}


function readTodosFromLocalStorage() {
    console.log('readTodosFromLocalStorage')
    const todos = localStorage.getItem('todos');
    return todos ? JSON.parse(todos) : [];
}

function filteredTodo(todos, filterId) {
    if (filterId==='0') {
        console.log(todos)
        return todos;        
    }

    let filterValue = undefined;
    if (filterId==='1') {
        console.log('value 1')
        filterValue = 'active'
    } else if (filterId==='2') {
        console.log('value 2')
        filterValue = 'completed'
    }

    return todos.filter((t) => (t.status===filterValue))
}

