import React from 'react';
import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import styles from './SubFilter.module.css'

export default function SubFilter({name, filterIdx, selected, onClick}) {
    const {filterIndex, setFilterIndex} = useContext(GlobalContext)
    // let selected = false;

    const handleClick = () => {
        onClick ?  onClick() : console.log('no onClick')
        setFilterIndex(filterIdx);
    }

    return (
        <>
            <button className={`${styles.filter} ${selected==='true' && styles.selected}`} onClick={handleClick}>{name}</button>
        </>
    );
}
