import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import styles from './SubItem.module.css'

export default function SubItem({key, todo, onUpdate, onDelete}) {
    const {id, text, status} = todo;
    const handleChange = (e) => {
        const status = e.target.checked ? 'completed' : 'active';
        onUpdate({ ...todo, status })
    }
    const handleDelete = () => {
        onDelete(todo)
    }

    return (
        <li className={styles.todo}  key={key}>
            <input className={styles.checkbox} 
                type='checkbox' 
                id={id}
                checked={status==='completed'} 
                onChange={handleChange} />
            <label className={styles.text} htmlFor={id}>{text}</label>
            <span className={styles.icon}>
                <button className={styles.button} onClick={handleDelete}> <AiFillDelete /></button>
            </span>
            {/* <AiFillDelete /> */}
            {/* <span>{todo}</span>
            <AiFillDelete /> */}
        </li>
    );
}

